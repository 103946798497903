body {
    margin: 1rem;
    padding: 1rem;
}

.d1 {
  margin: 0.5rem;
  padding: 0.5rem;
}

